/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-02 17:11:51
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-15 13:55:08
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerPosition = params => axios({
    url: '/api/dealer/dealer/dealerPosition/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerPosition = params => axios({
    url: '/api/dealer/dealer/dealerPosition/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerPosition = params => axios({
    url: '/api/dealer/dealer/dealerPosition/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerPosition = params => axios({
    url:'/api/dealer/dealer/dealerPosition/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerPosition = params => axios({
    url: '/api/dealer/dealer/dealerPosition/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
